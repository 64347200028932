import SiteTail from './SiteTail';
import SiteHeader from './SiteHeader';
const MainLayout = ({ children }) => (
    <>
      <SiteHeader />
      {children}
      <SiteTail />
    </>
  );
  
  export default MainLayout;
  