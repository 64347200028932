import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
function getCurrentSchoolYear() {
  const today = new Date();
  const year = today.getFullYear();
  const month = today.getMonth();

  // School year changes on the first of July
  if (month >= 6) {
    // Months are 0-indexed in JavaScript, so 6 corresponds to July
    return `${year}-${year + 1}`;
  } else {
    return `${year - 1}-${year}`;
  }
}

function isLinkActive() {
  const now = new Date();
  const startDate = new Date(now.getFullYear(), 7, 2, 12); // August 2nd, 12 PM
  const endDate = new Date(now.getFullYear(), 6, 1, 0, 0, 0); // July 1st, 12 AM

  // Link is active if it's after August 2nd at noon OR before July 1st
  return now >= startDate || now < endDate;
}


const SchoolYear = () => {
  const currentSchoolYear = getCurrentSchoolYear();
  const linkActive = isLinkActive();

  return (
    <div className="textelement">
      <Helmet>
        <title>Lid Worden | Scouts 121 Oude-God Mortsel</title>
        <meta
          name="description"
          content="Wat staat er allemaal te gebeuren deze maand? De leiding heeft voor hun leden weer een programma in elkaar gestoken van ravotten, spelen en knutselen. Niet elke tak doet hetzelfde, natuurlijk niet. Lees daarom het programma na van jouw tak. Groepsleiding | Kapoenen | Welka's | Jojo's | Jonggivers | Givers Vergeet niet om ook de activiteiten in het oog te houden."
        />
        <meta
          name="keywords"
          content="contact, inschrijven, e-mail, scouts121, 121, scouts"
        />
        <meta name="HandheldFriendly" content="true" />
      </Helmet>
      <h1>Lid worden?</h1>

      <h2>{currentSchoolYear}</h2>
      <p>
        De inschrijvingen voor het volgende scoutsjaar gaan voor bestaande leden
        van start op 5 augustus 2024. Zij krijgen ongeveer 4 weken voorrang op
        nieuwe leden.
      </p>
      <p>
        Nieuwe leden kunnen inschrijven vanaf maandag 2 september 2024, dit is
        net voor onze jaarlijkse startdag. Op onze startdag (14/09) kunnen
        nieuwe leden al voor een eerste keer kennis komen maken met onze scouts
        en met hun, hopelijk, toekomstige leidingsploeg. Ter info: eerstejaars
        kapoentjes gaan volgend scoutsjaar naar het 1ste leerjaar (geboortejaar
        2018). Kinderen kunnen niet op de wachtlijst gezet worden voor de
        inschrijvingsdatum. De inschrijvingen openen om 12u00 en worden
        chronologisch afgehandeld.
      </p>
      <h3>
        Inschrijvingen gebeuren digitaal via een formulier dat{' '}
        {linkActive ? (
          <Link to="/inschrijven/" className="orange">
            hier
          </Link>
        ) : (
          'hier'
        )}{' '}
        op de website online komt.
      </h3>
      <p>
        Het lidgeld bedraagt dit jaar €65 per kind (we weten dat dit meer is dan
        vorig jaar, we zijn helaas verplicht ons lidgeld te verhogen aangezien
        Scouts en Gidsen Vlaanderen dit ook moet doen. De verklaring hiervoor
        kan je hier lezen) In het lidgeld zit de inschrijving en de verzekering
        inbegerepen voor alle activiteiten die we als scouts doen. Het lidgeld
        kan je storten op volgend rekeningnummer BE63 7360 1136 2808 met
        vermelding van "NAAM & GEBOORTEJAAR + TAK".
      </p>
      <p>
        Kan het ook voor minder geld? Voor wie het een hulp kan zijn, bestaat
        Scouting op maat. U betaalt dan slechts €22,00. Als u hier graag van
        gebruik maakt, laat het ons dan zeker weten! Wij behandelen deze
        aanvraag discreet. (Mail naar: groepsleiding@scouts121.be)
      </p>

      <h2>Was uw kind al lid?</h2>
      <p>
        Herinschrijvingen, broertjes of zusjes krijgen vanaf 5 augustus tot 1
        september voorrang om zich in te schrijven. Vanaf dan wordt de plek niet
        meer vrijgehouden en dus doorgegeven aan iemand uit de wachtlijst. De
        betaling moet ook voor 14 oktober gebeurd zijn. Zo niet, dan zijn we
        genoodzaakt €15 administratiekosten aan te rekenen. Dit geldt enkel voor
        bestaande leden, nieuwe leden (broertjes/zusjes) krijgen eerst een maand
        om de scouts wat uit te testen!
      </p>
      <p>
        Het herinschrijven van uw kind of het inschrijven van broertjes/zusjes
        kan via het google doc dat u eerder deze maand zou moeten hebben
        ontvangen. U kan het document ook <a href='https://forms.gle/sCfx5kgoxdQCwt4bA'>hier</a> invullen. Gelieve als opmerking de naam van de reeds ingeschreven
        broer/zus te vermelden.
      </p>
      <h3>Mocht u nog vragen hebben, kan u ons steeds contacteren op</h3>
      <h3>groepsleiding@scouts121.be</h3>
    </div>
  );
};

export default SchoolYear;
