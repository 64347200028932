import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { ApolloClient, InMemoryCache, ApolloProvider } from '@apollo/client';

// page & layout imports
import Homepage from './pages/Homepage';
import Knoop from './pages/DeKnoop';
import Jetlag from './pages/Jetlag';
import DeMol from './pages/DeMol';
import Stock from './pages/Stock';
import MainLayout from './components/MainLayout';
import KnoopHeader from './components/KnoopHeader';
import NoHeaderLayout from './components/NoHeaderLayout';
import DeGroep from './pages/DeGroep';
import Takken from './pages/TakkenEnLeiding';
import Tak from './pages/Tak';
import Verhuur from './pages/Verhuur';
import Contact from './pages/Contact';
import LidWorden from './pages/LidWorden';
import VerhuurInfo from './components/VerhuurInfo';
import Login from './components/login';
import Event from './pages/EventDetails';
import Inschrijven from './pages/inschrijven';
import Calender from './pages/leidingscalender';
import { Helmet } from 'react-helmet';
import PrivateRoute from './components/PrivateRoute';
import { AuthProvider } from './components/AuthContext';
import C from './components/sliderWidget/SliderWidget';
const client = new ApolloClient({
  uri: 'https://api.scouts121.be/graphql',
  cache: new InMemoryCache(),
});

function App() {
  return (
    <ApolloProvider client={client}>
      <AuthProvider>
        <Router>
          <Helmet>
            <title>Scouts 121</title>
            <meta name="description" content="this is a description" />
          </Helmet>
          <div className="App">
            <Switch>
              {/* Routes without Header */}
              <Route exact path="/demol">
                <NoHeaderLayout>
                  <DeMol />
                </NoHeaderLayout>
              </Route>

              <Route exact path="/aandelen">
                <NoHeaderLayout>
                  <Stock />
                </NoHeaderLayout>
              </Route>
              <Route exact path="/Jetlag">
                <NoHeaderLayout>
                  <Jetlag />
                </NoHeaderLayout>
              </Route>
              {/* All other routes use MainLayout */}
              <Route>
                <MainLayout>
                  <Switch>
                    <Route exact path="/" component={Homepage} />
                    <Route exact path="/degroep" component={DeGroep} />
                    <Route exact path="/verhuur" component={Verhuur} />
                    <Route exact path="/verhuur/:id" component={VerhuurInfo} />
                    <Route exact path="/takken" component={Takken} />
                    <Route exact path="/takken/:id" component={Tak} />
                    <Route path="/deknoop/:id">
                      <KnoopHeader />
                      <Knoop />
                    </Route>
                    <Route path="/deknoop/">
                      <KnoopHeader />
                    </Route>
                    <Route path="/Inschrijving_Scouts121/" component={LidWorden} />
                    <Route path="/l/" component={C} />
                    <Route path="/inschrijven/" component={Inschrijven} />
                    <Route path="/contact/" component={Contact} />
                    <Route path="/evenementen/:id" component={Event} />
                    <Route path="/login/" component={Login} />
                    <PrivateRoute path="/calenderleiding/" component={Calender} />
                  </Switch>
                </MainLayout>
              </Route>
            </Switch>
          </div>
        </Router>
      </AuthProvider>
    </ApolloProvider>
  );
}



export default App;
