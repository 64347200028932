import { useState, useEffect } from "react";
import gusImage from "../../../logos/gus.jpg";
export default function PainterComponent({ painter, location, image }) {
  const [timeLeft, setTimeLeft] = useState(900); // 10 minutes in seconds

  useEffect(() => {
    if (timeLeft <= 0) return;
    const timer = setInterval(() => setTimeLeft((prev) => prev - 1), 1000);
    return () => clearInterval(timer);
  }, [timeLeft]);

  function formatTime(seconds) {
    const minutes = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${minutes}:${secs < 10 ? "0" : ""}${secs}`;
  }

  return (
    <div style={{ border: "2px solid white", padding: "20px", marginTop: "20px", textAlign: "center" }}>
      <h2>Busk opdracht</h2>
      
      <p>Beste kandidaten<br /><br />

Jullie opdracht is simpel, jullie hebben 15 minuten de tijd om een straat optreden te houden en zo 1 euro te verdienen. Dit kan met een dansje, een liedje, een goocheltruc, ... Wees creatief. De opdracht is geslaagt als jullie binnen de tijd 1 euro hebben verdient. deze hele opdracht moet gefilmd worden inclussief de transactie. Jullie mogen dit ook in groep doen. 
Als jullie dit gedaan hebben kunnen jullie jullie verplaatsen naar het museum Mayer van den Bergh en "musea" ingeven voor jullie volgende opdracht. </p>

<h3 style={{ color: "red", marginTop: "10px" }}>Time Left: {formatTime(timeLeft)}</h3>
    </div>
  );
}