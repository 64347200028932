import React, { useEffect } from 'react';

const Facebook = () => {
  useEffect(() => {
    // Dynamically load the Facebook SDK script
    const script = document.createElement('script');
    script.src =
      'https://connect.facebook.net/nl_NL/sdk.js#xfbml=1&version=v20.0';
    script.async = true;
    script.defer = true;
    script.crossOrigin = 'anonymous';
    script.nonce = 'rPtsnkyY';
    document.body.appendChild(script);

    // Cleanup function to remove the script
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div
      className="grid-row-2 overflow"
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: '100%',
        
      }}
    >
      <div
        className="fb-page"
        data-href="https://www.facebook.com/scouts121OudeGod"
        data-show-posts="true"
        data-width="100%"  // Ensures it takes full width of container
        data-height="100%" // Ensures it takes full height of container
        data-small-header="false"
        data-adapt-container-width="true"
        data-hide-cover="false"
        data-show-facepile="true"
        style={{
          borderRadius: '12px',
          boxShadow: '0 6px 15px rgba(0, 0, 0, 0.2)',
          width: '100%',
          height: '100%',
          
        }}
      >
        <blockquote
          cite="https://www.facebook.com/scouts121OudeGod"
          className="fb-xfbml-parse-ignore"
          style={{
            width: '100%',
            height: '100%',
            margin: '0',
          }}
        >
          <a href="https://www.facebook.com/scouts121OudeGod">Scouts 121</a>
        </blockquote>
      </div>
    </div>
  );
};

export default Facebook;
