import React from 'react';
import { Link } from 'react-router-dom';
import { useQuery, gql } from '@apollo/client';
import Map from '../components/Jetlag/Map';
import Facebook from '../components/facebook';
import Events from '../components/sliderWidget/SliderWidget';


export default function Homepage() {
  return (
    <div>
     
      <Map />
    
      
    </div>
  );
} 
