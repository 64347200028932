import React, { useState, useEffect } from "react";
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from "recharts";

// Initial stock data (original prices)
const stocks = [
  { id: 0, name: "Playboy Enterprises, INC.", originalPrice: 100 },
  { id: 10, name: "American Huxfer Prison", originalPrice: 150 },
  { id: 23, name: "Ford Motor Company", originalPrice: 200 },
  { id: 37, name: "Tesla", originalPrice: 50 },
  { id: 43, name: "Boeing", originalPrice: 120 },
  { id: 59, name: "Trump Casino", originalPrice: 75 },
  { id: 64, name: "Disney", originalPrice: 250 },
  { id: 79, name: "CocaCola Company", originalPrice: 300 }
];

// Generate a user-specific seed (this would ideally come from the user session or local storage)
const getUserSeed = () => Math.random() * 0.6 - 0.3; // A random value between -0.2 and 0.2 (20% difference)

const userSeed = getUserSeed(); // User-specific seed to create more price variation (20% difference)

// Modified calculateStockPrice to slow down fluctuation, add more randomness, and incorporate user-specific variation
const calculateStockPrice = (originalPrice, stockId) => {
  const time = Date.now() / 1000; // current time in seconds
  const fluctuationFactor = Math.sin(time / 500 + stockId); // Slower fluctuation (divided by 300 for longer period)
  const randomFactor = Math.random() * 0.5 - 0.25; // Increase randomness to range [-0.15, 0.15]

  const minMultiplier = 0.25;
  const maxMultiplier = 2;

  // The stock price fluctuates between 25% and 200% of its original value, with randomness
  const multiplier = minMultiplier + (maxMultiplier - minMultiplier) * ((fluctuationFactor + randomFactor + 1) / 2);

  // Adding user seed for slight difference in prices
  const userAdjustment = 1 + userSeed; // User-specific price adjustment

  const newPrice = originalPrice * multiplier * userAdjustment;

  // Log for debugging
  console.log(`Stock ID: ${stockId}, Original Price: ${originalPrice}, New Price: ${newPrice}`);

  return newPrice;
};

const StockPage = () => {
  // Track the prices of each stock over time, preserving the original price
  const [priceHistory, setPriceHistory] = useState(
    stocks.map(stock => ({
      id: stock.id,
      name: stock.name,
      originalPrice: stock.originalPrice,
      prices: [{ time: Date.now(), price: stock.originalPrice }]
    }))
  );

  useEffect(() => {
    const interval = setInterval(() => {
      setPriceHistory(prevHistory => {
        return prevHistory.map(stock => {
          const newPrice = calculateStockPrice(stock.originalPrice, stock.id);

          // Check for valid price before updating
          if (isNaN(newPrice)) {
            console.error(`Invalid price for ${stock.name}:`, newPrice);
            return stock;
          }

          const newPriceData = { time: Date.now(), price: newPrice };
          return { ...stock, prices: [...stock.prices, newPriceData] };
        });
      });
    }, 60000); // Update every 60 seconds (1 minute)

    return () => clearInterval(interval);
  }, []);

  const containerStyle = {
    padding: "20px",
    margin: "0 auto",
    maxWidth: "1200px",
  };

  const stockSectionStyle = {
    marginBottom: "30px",
    padding: "15px",
    border: "1px solid #ddd",
    borderRadius: "10px",
    backgroundColor: "#f9f9f9",
  };

  const ulStyle = {
    marginTop: "20px",
  };

  const h2Style = {
    marginBottom: "15px",
  };

  return (
    <div style={containerStyle}>
      <h1>Stock Prices Over Time</h1>
      {priceHistory.map(stock => (
        <div key={stock.id} style={stockSectionStyle}>
          <h2 style={h2Style}>{stock.name}</h2>
          <ResponsiveContainer width="100%" height={300}>
            <LineChart data={stock.prices}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="time" tickFormatter={(time) => new Date(time).toLocaleTimeString()} />
              <YAxis />
              <Tooltip labelFormatter={(value) => new Date(value).toLocaleTimeString()} />
              <Legend />
              <Line type="monotone" dataKey="price" stroke="#8884d8" />
            </LineChart>
          </ResponsiveContainer>
          <ul style={ulStyle}>
            {stock.prices.slice(-1).map((priceData, index) => (
              <li key={index}>
                {stock.name}: ${Math.round(priceData.price)} (last updated at {new Date(priceData.time).toLocaleTimeString()})
              </li>
            ))}
          </ul>
        </div>
      ))}
    </div>
  );
};

export default StockPage;
