import { useState, useEffect } from "react";
import deMol from "../logos/demol.jpg";
import clockSound from "../sound/clock.mp3";

// Importing separate React components for each password
import MolComponent from "../components/DeMolOpdrachten/MolInstructies";
import KandidaatComponent from "../components/DeMolOpdrachten/KandidaatInstructies";
import PainterComponent from "../components/DeMolOpdrachten/Herken/HerkenOpdracht";
import Buildingcomponent from "../components/DeMolOpdrachten/schatting/schatting";
import Cas from "../components/DeMolOpdrachten/Code/Cas";

import Jill from "../components/DeMolOpdrachten/Code/Jill";
import Nello from "../components/DeMolOpdrachten/Foto/Nello";
import Gus from "../components/DeMolOpdrachten/Foto/Gus";
import Dieren from "../components/DeMolOpdrachten/Onthoud/Dieren";
import Felix from "../components/DeMolOpdrachten/Onthoud/Felix";
import Mas from "../components/DeMolOpdrachten/Groeps/Communicatie";
import Cairn from "../components/DeMolOpdrachten/Groeps/Curse";
import Busk from "../components/DeMolOpdrachten/Groeps/Busk";
import Plantin from "../components/DeMolOpdrachten/Groeps/BoekdrukKunst";
import TicketToRide from "../components/DeMolOpdrachten/Groeps/TicketToRide";
import Art from "../components/DeMolOpdrachten/Groeps/Art";

export default function GamePage() {
  const targetDate = new Date("2025-03-30T09:30:00").getTime();
  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());
  const [audioStarted, setAudioStarted] = useState(false);
  const [password, setPassword] = useState("");
  const [passwordMessage, setPasswordMessage] = useState(null);
  const [unlockedComponents, setUnlockedComponents] = useState([]);
  const audio = new Audio(clockSound);

  const passwords = [
    { password: "B3nnyD3M0l", message: "Welkom Mol", component: <MolComponent /> },
    { password: "ruimtenevelblauwebeheersdeperdix", message: "Welkom Kandidaat", component: <KandidaatComponent /> },

    { password: "fr4nkr1jk", message: "Welkom Kandidaat", component: <Cas /> },

    { password: "du1tsl4nd", message: "Welkom Kandidaat", component: <Jill /> },

 { password: "wachtwoord", message: "Welkom Kandidaat", component: <Gus /> },
 { password: "vleeshuis", message: "Welkom Kandidaat", component: <Nello /> },

  { password: "geheugen", message: "Welkom Kandidaat", component: <Dieren /> },
   { password: "hetscheldt", message: "Welkom Kandidaat", component: <Felix /> },

   { password: "groenplaats", message: "Welkom Kandidaat", component: <Busk /> },
   { password: "start", message: "Welkom Kandidaat", component: <Art /> },
  { password: "vandevelde", message: "Welkom Kandidaat", component: <Mas /> },
    { password: "tuin", message: "Welkom Kandidaat", component: <Cairn /> },
 { password: "boeken", message: "Welkom Kandidaat", component: <Plantin /> },
  { password: "deNMBSzijnwankers", message: "Welkom Kandidaat", component: <TicketToRide /> },

   { password: "4ntw3rp3n", message: "Welkom Kandidaat", component: <PainterComponent painter="Rubens" raadsel="De oudste drukkerij van Antwerpen!" loop="boeken" /> },
   { password: "musea", message: "Welkom Kandidaat", component: <PainterComponent painter="Jan Breughel den oude" raadsel="Bloemen, struiken, cactussen... deze tuin aan een chic hotel heeft het allemaal!" loop="tuin" /> },
   // { password: "monet789", message: "You found the secret, Player 4!", component: <PainterComponent painter="Claude Monet" location="Giverny, France" image="monet.jpg" /> },
   // { password: "rembrandt101", message: "You found the secret, Player 5!", component: <PainterComponent painter="Rembrandt van Rijn" location="Amsterdam, Netherlands" image="rembrandt.jpg" /> },

    { password: "dwayne", message: "Welkom Kandidaat", component: <Buildingcomponent location="het steen" code="Jullie volgende lokatie bevind zich min of meer tussen het steen en de groenplaats. geen stress dat je het nog niet weet, geef het volgende wachtwoord in en alles zal snel duidelijk zijn. het wachtwoord is wachtwoord " /> },
    { password: "vegetariërs", message: "welkom Kandidaat", component: <Buildingcomponent location="het vleeshuis"  code="Jullie volgende lokatietip is Ssssstttt, stil zijn! Hier slaapt een jongen met zijn beste kameraad... het wachtwoord is vleeshuis" /> },
   // { password: "hetstadshuis789", message: "You found the secret, Player 4!", component: <Buildingcomponent location="Giverny, France" image="monet.jpg" /> },
   // { password: "dekathedraal101", message: "You found the secret, Player 5!", component: <Buildingcomponent location="Amsterdam, Netherlands" image="rembrandt.jpg" /> },
  ];
  

  function calculateTimeLeft() {
    const now = new Date().getTime();
    const difference = targetDate - now;
    return {
      days: Math.floor(difference / (1000 * 60 * 60 * 24)),
      hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
      minutes: Math.floor((difference / (1000 * 60)) % 60),
      seconds: Math.floor((difference / 1000) % 60),
    };
  }

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);
    return () => clearInterval(timer);
  }, []);

  const startAudio = () => {
    if (!audioStarted) {
      audio.loop = true;
      audio.volume = 0.5;
      audio.play().catch((err) => console.error("Audio playback error:", err));
      setAudioStarted(true);
    }
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handlePasswordSubmit = () => {
    const foundPassword = passwords.find((entry) => entry.password === password);
    if (foundPassword) {
      setPasswordMessage(foundPassword.message);
      setUnlockedComponents([foundPassword.component]);
    } else {
      setPasswordMessage("Wrong Password, Try Again!");
    }
  };
  useEffect(() => {
    console.log("Beste Kandidaat, u heeft mijn geheime tips gevonden, indrukwekkend. Tip 1. kijk goed naar jullie eerste wachtwoord, wat is dit? herken je dit? Ken je andere woorden in dezelfde categorie? Tip 2. jullie locate bevind zich in het midden van een kruispunt.");
  }, []);
  return (
    <div
      onClick={startAudio}
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        minHeight: "100vh",
        backgroundColor: "black",
        color: "white",
        padding: "20px",
        textAlign: "center",
        fontFamily: "'Anton', sans-serif",
      }}
    >
      <img src={deMol} alt="Game Image" style={{ width: "256px", height: "auto", marginBottom: "24px" }} />

      <div style={{ fontSize: "48px", fontWeight: "bold", textTransform: "uppercase", letterSpacing: "4px", marginBottom: "16px" }}>
        {timeLeft.days}D {timeLeft.hours}H {timeLeft.minutes}M {timeLeft.seconds}S
      </div>

      <input
        type="password"
        value={password}
        onChange={handlePasswordChange}
        placeholder="Enter Password"
        style={{ padding: "10px", fontSize: "16px", borderRadius: "5px", border: "1px solid #ccc", textAlign: "center" }}
      />
      <button
        onClick={handlePasswordSubmit}
        style={{ padding: "10px 20px", marginTop: "10px", fontSize: "16px", cursor: "pointer", borderRadius: "5px", backgroundColor: "#444", color: "white" }}
      >
        Submit
      </button>

      {passwordMessage && (
        <div style={{ marginTop: "20px", color: passwordMessage.includes("Wrong") ? "red" : "green", fontWeight: "bold", fontSize: "18px" }}>
          {passwordMessage}
        </div>
      )}

      <div style={{ marginTop: "20px", width: "100%" }}>
        {unlockedComponents.map((Component, index) => (
          <div key={index} style={{ marginTop: "20px" }}>{Component}</div>
        ))}
      </div>
    </div>
  );
}
