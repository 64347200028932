import React, { useState, useEffect } from "react";
import { MapContainer, TileLayer, Rectangle, Polygon, useMap, Marker } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import "./jetlag.css"; // Import the CSS file

// Define initial sectors with complex shapes
const initialSectors = [
  {
    id: 1,
    type: "polygon", // Define shape type
    bounds:  [
      [
        4.570589577264286,
        51.12871428771513
      ],
      [
        4.570442434819739,
        51.12877584941978
      ],
      [
        4.5702537906594785,
        51.12871665547459
      ],
      [
        4.569597308984356,
        51.12833781242779
      ],
      [
        4.569461485190345,
        51.128243101180374
      ],
      [
        4.569431302125139,
        51.128053678103186
      ],
      [
        4.5694916682555515,
        51.12798737984252
      ],
      [
        4.570200970295701,
        51.127504346785855
      ],
      [
        4.570234926243529,
        51.12743804773666
      ],
      [
        4.570759357009877,
        51.126930176524155
      ],
      [
        4.5707518112432695,
        51.126873348067306
      ],
      [
        4.57002364478717,
        51.12658210112775
      ],
      [
        4.569710495481331,
        51.12637378632601
      ],
      [
        4.569721814130617,
        51.126298014121716
      ],
      [
        4.5699255498228695,
        51.12614410144897
      ],
      [
        4.570174560113514,
        51.12609200781276
      ],
      [
        4.570668807810904,
        51.126155940903516
      ],
      [
        4.571212102990614,
        51.12631695718426
      ],
      [
        4.571744079519817,
        51.12642114389047
      ],
      [
        4.572468473091931,
        51.12644482265449
      ],
      [
        4.5724722459758596,
        51.12656795203188
      ],
      [
        4.571272469120686,
        51.127871139450065
      ],
      [
        4.571098916493639,
        51.12805345989662
      ],
      [
        4.57103100459662,
        51.12807003444706
      ],
      [
        4.570646170511935,
        51.12843230527582
      ],
      [
        4.570555621315123,
        51.12858384255742
      ],
      [
        4.570589577264286,
        51.12871428771513
      ]
    ]
,
    owner: null,
    selected: false,
  },

  {
    id: 1,
    type: "polygon", // Define shape type
    bounds:  [
      [
        4.576048932179361,
        51.12867276010019
      ],
      [
        4.576086661011118,
        51.12876983822326
      ],
      [
        4.576328125535184,
        51.12879114802769
      ],
      [
        4.576320579769799,
        51.1289095356517
      ],
      [
        4.576109298310996,
        51.12893321313999
      ],
      [
        4.576180983091916,
        51.12918419377027
      ],
      [
        4.576075342361861,
        51.129302580386764
      ],
      [
        4.57640735608274,
        51.12962932587317
      ],
      [
        4.575698054043812,
        51.129903979711486
      ],
      [
        4.575479226818345,
        51.129896876615334
      ],
      [
        4.575090619849789,
        51.12969325406755
      ],
      [
        4.574607690801656,
        51.12969562177682
      ],
      [
        4.574139853285573,
        51.12946121797671
      ],
      [
        4.574004029491562,
        51.129089484258884
      ],
      [
        4.573988937958376,
        51.1290231874851
      ],
      [
        4.57329095456879,
        51.129018451997496
      ],
      [
        4.57346073431188,
        51.128686966667544
      ],
      [
        4.573928571827963,
        51.12870354099067
      ],
      [
        4.573747473434338,
        51.12837678895434
      ],
      [
        4.573773883616866,
        51.128208676498645
      ],
      [
        4.573917253177427,
        51.128227618777544
      ],
      [
        4.575935745683921,
        51.12824656104908
      ],
      [
        4.576418674732054,
        51.12820157314178
      ],
      [
        4.57640735608274,
        51.1287059087507
      ],
      [
        4.576048932179361,
        51.12867276010019
      ]
    ]
,
    owner: null,
    selected: false,
  },
  {
    id: 1,
    type: "polygon", // Define shape type
    bounds:  [
      [
        4.570369136268994,
        51.130242319953794
      ],
      [
        4.570496685971847,
        51.13040356745768
      ],
      [
        4.570194255531618,
        51.130830984033366
      ],
      [
        4.5701150445027565,
        51.13117123126284
      ],
      [
        4.5699377630660365,
        51.131302264092966
      ],
      [
        4.569593723751552,
        51.13147819172079
      ],
      [
        4.569456153409931,
        51.13153867592385
      ],
      [
        4.569188471924463,
        51.131750756528504
      ],
      [
        4.569004249972437,
        51.13167768559646
      ],
      [
        4.56869497537329,
        51.131389704715616
      ],
      [
        4.568557582879606,
        51.131166297156824
      ],
      [
        4.568532092299648,
        51.1310808005947
      ],
      [
        4.56961754257847,
        51.13070211786291
      ],
      [
        4.569786667840532,
        51.13063180267133
      ],
      [
        4.570369136268994,
        51.130242319953794
      ]
    ]
,
    owner: null,
    selected: false,
  },
  {
    id: 1,
    type: "polygon", // Define shape type
    bounds:  [
      [
        4.568582523580886,
        51.12949448947367
      ],
      [
        4.5673831486150505,
        51.12997764735789
      ],
      [
        4.566737404623069,
        51.130469807045756
      ],
      [
        4.566469950171154,
        51.13092725956878
      ],
      [
        4.566261999344903,
        51.131428526531636
      ],
      [
        4.566357300958799,
        51.13162227738985
      ],
      [
        4.565909423310501,
        51.13164228259987
      ],
      [
        4.5651801820852995,
        51.1315368899665
      ],
      [
        4.562576921332692,
        51.13064305913679
      ],
      [
        4.563141462611895,
        51.12977607360935
      ],
      [
        4.5638910148057334,
        51.12906968981312
      ],
      [
        4.564714933924819,
        51.12805951029719
      ],
      [
        4.56531555984327,
        51.12727997220921
      ],
      [
        4.565550227450927,
        51.12673915612504
      ],
      [
        4.568582523580886,
        51.12949448947367
      ]
    ]
,
    owner: null,
    selected: false,
  },
  {
    id: 1,
    type: "polygon", // Define shape type
    bounds:  [
      [
        4.568959082548332,
        51.12804944229566
      ],
      [
        4.568880861351175,
        51.12809323116164
      ],
      [
        4.5691180945317456,
        51.12827403767017
      ],
      [
        4.569056726222982,
        51.12836138228781
      ],
      [
        4.568898368851507,
        51.12839024781755
      ],
      [
        4.56892581111299,
        51.128420360222066
      ],
      [
        4.5683953526860535,
        51.12855605004302
      ],
      [
        4.567819610549861,
        51.12875360474692
      ],
      [
        4.566431221878702,
        51.12753827465124
      ],
      [
        4.566825452253994,
        51.127354051812745
      ],
      [
        4.567215248402363,
        51.12718712956334
      ],
      [
        4.5678707653910635,
        51.12700337770744
      ],
      [
        4.568895914995068,
        51.12789627971847
      ],
      [
        4.568843540587039,
        51.12792169313033
      ],
      [
        4.568959082548332,
        51.12804944229566
      ]
    ]
,
    owner: null,
    selected: false,
  },
  {
    id: 1,
    type: "polygon", // Define shape type
    bounds:  [
      
    ]
,
    owner: null,
    selected: false,
  },
  {
    id: 1,
    type: "polygon", // Define shape type
    bounds:  [
      
    ]
,
    owner: null,
    selected: false,
  },
  {
    id: 1,
    type: "polygon", // Define shape type
    bounds:  [
      
    ]
,
    owner: null,
    selected: false,
  },
  {
    id: 1,
    type: "polygon", // Define shape type
    bounds:  [
      
    ]
,
    owner: null,
    selected: false,
  },
];


const UserLocationMarker = () => {
  const [position, setPosition] = useState(null);
  const map = useMap();

  useEffect(() => {
    navigator.geolocation.getCurrentPosition(
      (pos) => {
        const { latitude, longitude } = pos.coords;
        setPosition([latitude, longitude]);
        map.setView([latitude, longitude], 14);
      },
      (error) => {
        console.error("Location access denied", error);
      }
    );
  }, [map]);

  return position ? (
    
    <Marker
      position={position}
      icon={L.icon({
        iconUrl: "https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon.png",
        iconSize: [25, 41],
        iconAnchor: [12, 41],
      })}
    />
  ) : null;
};

const swapCoordinates = (bounds) => {
  return bounds.map(([lon, lat]) => [lat, lon]); // Swap longitude and latitude
};

const ClaimableMap = () => {
  const [sectors, setSectors] = useState(initialSectors);
  const [flipped, setFlipped] = useState(false);

  const handleClaim = (id) => {
    setSectors((prevSectors) =>
      prevSectors.map((sector) => {
        if (sector.id === id) {
          return { ...sector, selected: !sector.selected };
        }
        return sector;
      })
    );
  };

  const handleCardClick = () => {
    setFlipped(!flipped);
  };

  return (
    <div className="map-container-wrapper">
      <img
        src="https://upload.wikimedia.org/wikipedia/he/e/eb/Jet_Lag%2C_The_Game_Logo.svg.png"
        alt="Map Overlay"
        className="map-logo"
      />
      <MapContainer center={[51.132, 4.56]} zoom={14} className="map-container">
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OSM</a>'
        />
        <UserLocationMarker />
        {sectors.map(({ id, bounds, selected, type }) => {
          const swappedBounds = swapCoordinates(bounds); // Swap coordinates here
          const commonProps = {
            key: id,
            color: selected ? "green" : "blue",
            eventHandlers: { click: () => handleClaim(id) },
          };

          if (type === "polygon") {
            return <Polygon positions={swappedBounds} {...commonProps} />;
          } else if (type === "rectangle") {
            return <Rectangle bounds={swappedBounds} {...commonProps} />;
          }
          return null;
        })}
      </MapContainer>

      <div className="card-container">
        <div className={`playing-card ${flipped ? "flipped" : ""}`} onClick={handleCardClick}>
          <div className="card-inner">
            <div className="card-front">
              <img
                src="https://agentpalmer.com/wp-content/uploads/2024/04/Jet-Lag-The-Game-is-worth-the-cost-of-Subscription.jpg"
                alt="Card Back"
              />
            </div>
            <div className="card-back">
              <div className="card-content">
                <h2 className="card-title">CURSE OF THE CRUNCHY CARDBOARD</h2>
                <p className="card-body">
                  Before asking another question, the Seekers must acquire playing cards of some sort
                  (a deck of cards, a booster pack for a trading card game, etc.), and each Seeker must
                  eat a card. Jet Lag: The Game cards do not count.
                </p>
                <p className="card-cost"><strong>CASTING COST:</strong> Eat this card.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};


export default ClaimableMap;
