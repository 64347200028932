import React, { useEffect } from 'react';

const InstagramEmbed = () => {
  useEffect(() => {
    const processEmbeds = () => {
      if (window.instgrm) {
        window.instgrm.Embeds.process();
      }
    };

    const addInstagramScript = () => {
      if (document.querySelector('script[src="https://www.instagram.com/embed.js"]')) {
        processEmbeds(); // Script already exists, just process embeds
        return;
      }

      const script = document.createElement('script');
      script.src = 'https://www.instagram.com/embed.js';
      script.async = true;
      script.onload = processEmbeds;
      document.body.appendChild(script);
    };

    addInstagramScript();
  }, []);

  return (
    <div
      className="grid-row-3 grid-col-4 instagram-container"
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: '100%',
        padding: '10px',
      }}
    >
      <div
        style={{
          borderRadius: '12px',
          boxShadow: '0 6px 15px rgba(0, 0, 0, 0.2)',
          overflow: 'hidden',
          width: '100%',
          height: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <blockquote
          className="instagram-media"
          data-instgrm-permalink="https://www.instagram.com/scouts121/"
          data-instgrm-version="12"
          style={{
            width: '100%',
            height: '100%',
            margin: '0',
          }}
        ></blockquote>
      </div>
    </div>
  );
};

export default InstagramEmbed;
